<script setup>
import { Vue3Lottie } from "vue3-lottie";
import RamadanLampJson from "~/assets/lottie/ramadan-lamps.json";

const { isMobile } = useDevice();

// const isItNewYear = computed(async () => {
//   const moment = (await import("moment-timezone")).default;
//   const timezone = moment.tz.guess();
//   const now = moment.tz(timezone);
//   const newYear = new Date(now.year(), 0, 1);
//   return now.date() === newYear.getDate() && now.month() === newYear.getMonth() && now.date() < 3;
// });

// const showHappyNewYear = ref(false);

// onMounted(() => {
//   setTimeout(() => {
//     showHappyNewYear.value = false;
//   }, 5000); // 5 seconds
// });
</script>
<template>
  <div>
    <!-- <transition name="slide-up">
      <LazyHappyNewYear v-if="showHappyNewYear && isItNewYear" />
    </transition> -->
    <!-- <LazyFirework /> -->
    <!-- <LazySnowfall /> -->
    <!-- <div v-if="!isMobile" class="absolute md:right-2 md:!top-auto top-[-10rem]">
      <Vue3Lottie
        animationLink="https://lottie.host/dc6686b0-7194-42bc-9b89-e7a3b5f5286b/fTeHTTYykx.json"
        :height="700"
      />
    </div> -->
    <!-- <div v-if="!isMobile" class="absolute md:right-2 md:!top-auto top-[-10rem]">
      <Vue3Lottie
        animationLink="https://lottie.host/48eaf45b-6fc7-42f4-b9fb-cb79eeb16159/iijK1Kvqd1.json"
        :height="700"
      />
    </div> -->
    <!-- <div class="absolute md:right-2 md:!top-auto top-[-10rem]">
      <Vue3Lottie :animationData="RamadanLampJson" :height="750" />
    </div> -->
  </div>
</template>

<style>
/* Slide-up transition */
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 2s ease;
}

.slide-up-enter,
.slide-up-leave-to

/* Starting state for leave. */ {
  transform: translateY(0%);
}

.slide-up-leave-active {
  transform: translateY(-100%);
}
</style>
